.container-filters {
  z-index: 1;
  display: flex;
  padding: 10px;
  background-color: rgba(243, 243, 243, 0.8);
  gap: 10px;
}

.table__filter__input {
  flex: 1 1 200px;
  max-width: 300px;
}

.table__filter__input--full-width {
  max-width: 100%;
  width: 100%;
}
